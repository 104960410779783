<template>
    <div class="CreditsView ViewWrapper">
        <clv-head-meta :title="$t('Credits')"></clv-head-meta>
        <view-header>
            <h1>{{ $t('Credits') }}</h1>
        </view-header>
        <view-container>

            <div class="mt-4"></div>

            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="gk-card">
                        <table class="table">
                            <thead>
                            <tr>
                                <th></th>
                                <th>{{ $t('Available Credits') }}</th>
                            </tr>
                            </thead>
                            <tbody v-if="unitBalanceMap">
                            <tr>
                                <td>Email</td>
                                <td>{{ unitBalanceMap.EMAIL.balance }}</td>
                            </tr>
                            <tr>
                                <td>SMS</td>
                                <td>{{ unitBalanceMap.SMS.balance }}</td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <router-link :to="{ name: 'quotas' }">{{ $t('Service Quotas') }}</router-link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </view-container>
    </div>
</template>

<script>
/**
 * Credits (balance) view.
 *
 * @author Dimitris Gkoulis
 */
export default {
    name: 'CreditsView',
    data () {
        return {
            unitBalanceMap: null
        };
    },
    beforeMount () {
        // Get once. (it's better to retrieve quotas from system)
        this.unitBalanceMap = this.$store.getters['application/unitBalanceMap'];

        // Fetch all entityQlu (AGAIN).
        this.$store.dispatch('application/unitBalanceGetAllUnitBalances').then((data) => {
            this.unitBalanceMap = data;
        }).catch((ignoredError) => {
            this.unitBalanceMap = null;
            // @future : Handle.
        });
    }
};
</script>
